import React, { FC, HTMLProps, useRef } from "react";
import styled from "styled-components";

const StyledHeadline = styled.h3`
  font-size: 2rem;
  color: ${({ theme }) => theme.primary};
  display: flex;
  margin: 0;
  font-weight: 500;
  position: relative;
  padding-left: 15px;

  ::before {
    content: "•";
    color: ${({ theme }) => theme.primary};
    font-size: 2rem;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const SectionSubHeadline: FC<HTMLProps<HTMLHeadingElement>> = ({
  children,
  ...props
}) => {
  const ref = useRef();

  return (
    <StyledHeadline ref={ref} {...(props as any)}>
      {children}
    </StyledHeadline>
  );
};

export default SectionSubHeadline;
