import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChildImageSharp } from "@interface/ChildImageSharp";
import { TableItem } from "@interface/table";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Table from "@components/atoms/Table/Table";
import { cssEaseOutExpo } from "@theme/easing";
import { useMainContext } from "@context";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 30.19vw;
  max-width: 580px;
  height: 27.38vw;
  max-height: 525px;
  margin-bottom: 65px;

  @media (max-width: 1480px) {
    width: 35vw;
    height: 32vw;
  }

  @media (max-width: 720px) {
    width: 100%;
    height: 80.55vw;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  path {
    fill: ${({ theme }) => theme.primary};
    opacity: 0;
    transition: opacity 0.5s ${cssEaseOutExpo};

    &.is-active {
      opacity: 0.6;
    }
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledRightWrapper = styled.div`
  @media (min-width: 721px) {
    margin-left: 100px;
  }

  @media (max-width: 720px) {
    width: 100%;
    max-width: 480px;
  }
`;

const StyledHeadline = styled.h3`
  margin: 0 0 30px;
  font-size: 3.6rem;
  font-weight: 500;

  @media (max-width: 720px) {
    margin: 0 auto 20px;
    text-align: center;
  }
`;

const StyledTable = styled(Table)`
  margin: auto;
  width: 100%;

  @media (min-width: 721px) {
    width: 350px;
  }

  @media (min-width: 1025px) {
    width: 450px;
    max-width: unset;
  }

  @media (min-width: 1180px) {
    width: 500px;
  }
`;

const Floor: FC<Props> = ({
  image,
  name,
  table,
  className,
  vector: Vector,
  pathIDPrefix,
  id,
}) => {
  const [currPath, setCurrPath] = useState<number>(1);
  const imageWrapper = useRef<HTMLDivElement>();
  const { scroller } = useMainContext();

  useEffect(() => {
    if (!imageWrapper.current) return;

    const handleMouseEnter = (event: MouseEvent) => {
      setCurrPath(
        parseInt(
          (event.target as SVGElement).id.charAt(
            (event.target as SVGElement).id.length - 1
          )
        )
      );
    };

    const paths = imageWrapper.current.querySelectorAll("path");

    paths.forEach((path) => {
      path.addEventListener("mouseenter", handleMouseEnter);
    });

    return () => {
      paths.forEach((path) => {
        path.removeEventListener("mouseenter", handleMouseEnter);
      });
    };
  }, [imageWrapper, scroller]);

  useEffect(() => {
    if (!imageWrapper.current) return;

    imageWrapper.current
      .querySelector(".is-active")
      ?.classList.remove("is-active");
    imageWrapper.current
      .querySelector(`#${pathIDPrefix}-${currPath}`)
      ?.classList.add("is-active");
  }, [currPath, imageWrapper]);

  const handleTableItemEnter = (_: any, i: number) => setCurrPath(i + 1);

  return (
    <StyledWrapper className={className}>
      <StyledImageWrapper ref={imageWrapper}>
        <StyledImage
          image={getImage(image.childImageSharp.gatsbyImageData)}
          alt=""
        />
        <Vector />
      </StyledImageWrapper>
      <StyledRightWrapper>
        <StyledHeadline>{name}</StyledHeadline>
        <StyledTable
          items={table}
          activeItem={currPath}
          onItemEnter={handleTableItemEnter}
          id={id}
          /* @ts-ignore */
        />
      </StyledRightWrapper>
    </StyledWrapper>
  );
};

interface Props {
  image: ChildImageSharp;
  vector: FC<any>;
  pathIDPrefix: string;
  name: string;
  table: TableItem[];
  className?: string;
  id: string;
}

export default Floor;
