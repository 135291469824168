import React from "react";
import styled from "styled-components";
import IconBlock from "@components/atoms/IconBlock/IconBlock";
import {
  propertyIcon,
  forestIcon,
  familyIcon,
  houseWithGarageIcon,
} from "@assets/icons";

const StyledWrapper = styled.div`
  display: flex;
  margin: 80px auto;
  width: 90%;
  max-width: 1600px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 70px;
  }

  @media (max-width: 720px) {
    margin: 80px auto;
    gap: 40px;
  }
`;

const StyledIconBlock = styled(IconBlock)`
  margin-right: 20px;

  :last-of-type {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
  }
`;

const IconsSection = () => (
  <StyledWrapper>
    <StyledIconBlock icon={propertyIcon}>
      Kameralne osiedle domków jednorodzinnych
    </StyledIconBlock>
    <StyledIconBlock icon={forestIcon}>
      Blisko natury, w cichej i zielonej okolicy
    </StyledIconBlock>
    <StyledIconBlock icon={familyIcon}>
      Tworzone z myślą o komforcie mieszkańców
    </StyledIconBlock>
    <StyledIconBlock icon={houseWithGarageIcon}>
      Rąbień - świetnie miejsce do życia na przedmieściach Łodzi
    </StyledIconBlock>
  </StyledWrapper>
);

export default IconsSection;
