import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easing";

const StyledWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  padding: 15px;
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background 0.5s ${cssEaseOutExpo};

  svg {
    font-size: 3.2rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.primary};
    transition: color 0.5s ${cssEaseOutExpo};
  }

  :hover {
    background: ${({ theme }) => theme.primary};

    svg {
      color: ${({ theme }) => theme.background};
    }
  }

  @media (max-width: 720px) {
    align-items: center;
    width: 200px;
  }
`;

const StyledDescription = styled.p`
  margin: 0;
  transition: color 0.5s ${cssEaseOutExpo};

  span {
    font-weight: 700;
  }

  ${StyledWrapper}:hover & {
    color: ${({ theme }) => theme.background};
  }

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LocationBlock: FC<Props> = ({ icon, description, className }) => (
  <StyledWrapper className={className}>
    <Icon icon={icon} />
    <StyledDescription>{description}</StyledDescription>
  </StyledWrapper>
);

interface Props {
  icon: string;
  description: ReactNode;
  className?: string;
}

export default LocationBlock;
