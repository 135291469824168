import React from "react";
import styled from "styled-components";
import SectionSubHeadline from "@components/atoms/SectionSubHeadline/SectionSubHeadline";
import Column from "@components/molecules/Location/Column/Column";
import {
  carServiceIcon,
  cityIcon,
  fuelStationIcon,
  highwayIcon,
  horseIcon,
  mallIcon,
  manufactoryIcon,
  packageIcon,
  pharmacyIcon,
  playgroundIcon,
  schoolIcon,
  shopIcon,
} from "@assets/icons";
import Map from "@components/molecules/Location/Map/Map";

const StyledWrapper = styled.section`
  margin: 100px auto 0;

  @media (max-width: 720px) {
    margin: 80px auto 0;
  }
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  max-width: 1700px;
  margin: 0 auto;
`;

const StyledSubHeadline = styled(SectionSubHeadline)`
  order: -1;
`;

const StyledHeadline = styled.h2`
  font-size: 3.8rem;
  font-weight: 500;
  text-align: left;
  line-height: 1.3;
  margin: 20px 0 80px;

  @media (max-width: 1024px) {
    margin: 20px 0 80px;
  }

  @media (max-width: 720px) {
    font-size: 2.4rem;
    margin: 15px 0 60px;
  }
`;

const StyledColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

const StyledColumn = styled(Column)`
  :first-of-type {
    margin-right: 80px;
  }

  @media (max-width: 720px) {
    :first-of-type {
      margin-right: 0;
      margin-bottom: 60px;
    }
  }
`;

const Location = () => (
  <StyledWrapper id="location">
    <StyledInnerWrapper>
      <StyledHeadline>
        Osiedle jest położone w miejscu umożliwiającym bardzo dobrą komunikację
        - łatwy dojazd do Łodzi (10 km do Manufaktury) oraz do Aleksandrowa
        Łódzkiego
      </StyledHeadline>
      <StyledSubHeadline>Lokalizacja</StyledSubHeadline>

      <StyledColumnsWrapper>
        <StyledColumn
          title="Najbliższa okolica"
          description="Rozwinięta infrastruktura lokalna, w najbliższej okolicy znajdują się przedszkole, szkoła, sklepy, restauracje, punkty usługowe i medyczne."
          items={
            /* prettier-ignore */ [
          { icon: playgroundIcon, description: <>Przedszkole: <span>0,8 km</span></> },
          { icon: schoolIcon, description: <>Szkoła podstawowa: <span>1,2 km</span></> },
          { icon: shopIcon, description: <>Sklep spożywczy: <span>0,8 km</span></> },
          { icon: mallIcon, description: <>Centrum handlowe: <span>1 km</span></> },
          { icon: packageIcon, description: <>Paczkomat: <span>0,8 km</span></> },
          { icon: pharmacyIcon, description: <>Apteka: <span>0,8 km</span></> },
        ] /* END prettier-ignore */
          }
        />

        <StyledColumn
          title="Autem"
          description="Nowo powstająca obwodnica Łodzi S14 ułatwi znacząco podróżowanie w różnych kierunkach Polski – w kilka minut można się dostać na trasy A2 lub S8."
          items={
            /* prettier-ignore */ [
            { icon: manufactoryIcon, description: <>Łódź Manufaktura: <span>10 km</span></> },
            { icon: cityIcon, description: <>Aleksandrów Łódzki: <span>5 km</span></> },
            { icon: highwayIcon, description: <>Wjazd na S14: <span>3 km</span></> },
            { icon: fuelStationIcon, description: <>Stacja paliw: <span>4 km</span></> },
            { icon: carServiceIcon, description: <>Serwis samochodowy: <span>2 km</span></> },
            { icon: horseIcon, description: <>Stadnina koni: <span>2 km</span></> },
          ] /* END prettier-ignore */
          }
        />
      </StyledColumnsWrapper>
    </StyledInnerWrapper>

    <Map />
  </StyledWrapper>
);

export default Location;
