import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interface/ChildImageSharp";
import SectionSubHeadline from "@components/atoms/SectionSubHeadline/SectionSubHeadline";
import Floor from "@components/molecules/AboutHouses/Floor/Floor";
import { ROOMS } from "@config";
import { ReactComponent as GroundFloor } from "@assets/vectors/ground_floor.svg";
import { ReactComponent as FirstFloor } from "@assets/vectors/first_floor.svg";

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: -150px auto 0;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 720px) {
    margin: 80px auto 0;
  }
`;

const StyledSubHeadline = styled(SectionSubHeadline)`
  order: -1;
`;

const StyledHeadline = styled.h2`
  font-weight: 500;
  font-size: 3.2rem;
  margin: 20px 0 15px;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    margin: 15px 0;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 0 100px;

  @media (max-width: 720px) {
    margin: 0 0 60px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: auto;
`;

const StyledFloor = styled(Floor)`
  :last-of-type {
    margin-top: 50px;
  }
`;

const AboutHouses = () => {
  const { first, second } =
    useStaticQuery<{ first: ChildImageSharp; second: ChildImageSharp }>(query);

  return (
    <StyledWrapper id="about_houses">
      <StyledHeadline>Zobacz przestrzeń.</StyledHeadline>
      <StyledSubHeadline>Opis domów</StyledSubHeadline>
      <StyledParagraph>
        Zobacz, jak możesz zaaranżować 118,27 m<sup>2</sup> przestrzeni w Twoim
        domu.
      </StyledParagraph>

      <StyledInnerWrapper>
        <StyledFloor
          name="Parter"
          image={first}
          table={ROOMS.firstFloor}
          vector={GroundFloor}
          pathIDPrefix="ground-floor"
          id="ground-floor"
        />
        <StyledFloor
          name="Piętro"
          image={second}
          table={ROOMS.secondFloor}
          vector={FirstFloor}
          pathIDPrefix="first-floor"
          id="first-floor"
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    first: file(name: { eq: "ground_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    second: file(name: { eq: "first_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }
  }
`;

export default AboutHouses;
