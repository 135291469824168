import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 2.4rem;
  max-width: 360px;

  svg {
    font-size: 9.5rem;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 30px;
  }

  @media (max-width: 1360px) and (min-width: 1025px) {
    max-width: 280px;

    :nth-of-type(2),
    :nth-of-type(3) {
      max-width: 240px;
    }
  }

  @media (max-width: 720px) {
    font-size: 1.6rem;
    max-width: 180px;

    svg {
      font-size: 6.8rem;
      margin-bottom: 12px;
    }
  }
`;

const IconBlock: FC<Props> = ({ icon, children, className }) => {
  return (
    <StyledWrapper className={className}>
      <Icon icon={icon} />
      {children}
    </StyledWrapper>
  );
};

interface Props {
  icon: any;
  children: string;
  className?: string;
}

export default IconBlock;
