import React from "react";
import styled from "styled-components";
import Mapbox, { Marker } from "react-map-gl";
import { ReactComponent as Logo } from "@assets/vectors/logo-map.svg";
import mapbox from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapMarker from "@components/molecules/Location/Map/MapMarker/MapMarker";
import {
  carServiceIcon,
  cityIcon,
  fuelStationIcon,
  highwayIcon,
  horseIcon,
  mallIcon,
  manufactoryIcon,
  packageIcon,
  pharmacyIcon,
  playgroundIcon,
  schoolIcon,
  shopIcon,
} from "@assets/icons";
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapbox.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const StyledWrapper = styled.div`
  width: 100%;
  height: 39vw;
  max-height: 750px;
  position: relative;
  margin-top: 100px;

  @media (max-width: 720px) {
    height: 80vw;
    margin-top: 80px;
  }

  @media (max-width: 380px) {
    height: 100vw;
  }
`;

const StyledMapbox = styled(Mapbox)`
  width: 100%;
  height: 100%;
`;

const StyledLogo = styled(Logo)`
  width: 128px;
  height: auto;
`;

const Map = () => {
  return (
    <StyledWrapper>
      <StyledMapbox
        initialViewState={{
          latitude: 51.7845625,
          longitude: 19.3130781,
          zoom: 14,
        }}
        mapStyle="https://api.maptiler.com/maps/bright/style.json?key=YdGJFoDdkZpc2BQO4TzP"
        mapboxAccessToken={process.env.GATSBY_MAPS_API_KEY}
        cooperativeGestures
        antialias
      >
        <Marker latitude={51.7893125} longitude={19.3171875} onClick={() => {}}>
          <MapMarker
            icon={playgroundIcon}
            background="#fff"
            color="#9c481a"
            title="Przedszkole"
          />
        </Marker>

        <Marker latitude={51.7898125} longitude={19.3221875} onClick={() => {}}>
          <MapMarker
            icon={schoolIcon}
            background="#fff"
            color="#9c481a"
            title="Szkoła"
          />
        </Marker>

        <Marker latitude={51.7894375} longitude={19.3168125} onClick={() => {}}>
          <MapMarker
            icon={shopIcon}
            background="#fff"
            color="#9c1a4e"
            title="Sklep spożywczy"
          />
        </Marker>

        <Marker latitude={51.7896875} longitude={19.3203125} onClick={() => {}}>
          <MapMarker
            icon={mallIcon}
            background="#fff"
            color="#6c1a9c"
            title="Centrum handlowe"
          />
        </Marker>

        <Marker latitude={51.7893125} longitude={19.3169375} onClick={() => {}}>
          <MapMarker
            icon={packageIcon}
            background="#fff"
            color="#9c861a"
            title="Paczkomat"
          />
        </Marker>

        <Marker latitude={51.7898125} longitude={19.3173125} onClick={() => {}}>
          <MapMarker
            icon={pharmacyIcon}
            background="#fff"
            color="#1a9c99"
            title="Przychodnia i Apteka"
          />
        </Marker>

        <Marker latitude={51.7795625} longitude={19.4446875} onClick={() => {}}>
          <MapMarker
            icon={manufactoryIcon}
            background="#fff"
            color="#9c1a1a"
            title="Łódź Manufaktura"
          />
        </Marker>

        <Marker latitude={51.8197375} longitude={19.3035781} onClick={() => {}}>
          <MapMarker
            icon={cityIcon}
            background="#fff"
            color="#9c1a1a"
            title="Aleksandrów Łódzki"
          />
        </Marker>

        <Marker latitude={51.8022625} longitude={19.3235781} onClick={() => {}}>
          <MapMarker
            icon={highwayIcon}
            background="#fff"
            color="#1a9c93"
            title="Wjazd na S14 I"
          />
        </Marker>

        <Marker latitude={51.7679375} longitude={19.332046} onClick={() => {}}>
          <MapMarker
            icon={highwayIcon}
            background="#fff"
            color="#1a9c93"
            title="Wjazd na S14 II"
          />
        </Marker>

        <Marker latitude={51.8094375} longitude={19.3204375} onClick={() => {}}>
          <MapMarker
            icon={fuelStationIcon}
            background="#fff"
            color="#9c1a8a"
            title="Stacja paliw I"
          />
        </Marker>

        <Marker latitude={51.7886875} longitude={19.3230625} onClick={() => {}}>
          <MapMarker
            icon={fuelStationIcon}
            background="#fff"
            color="#9c1a8a"
            title="Stacja paliw II"
          />
        </Marker>

        <Marker latitude={51.7844375} longitude={19.3235625} onClick={() => {}}>
          <MapMarker
            icon={carServiceIcon}
            background="#fff"
            color="#222a78"
            title="Serwis samochodowy"
          />
        </Marker>

        <Marker latitude={51.7869375} longitude={19.2949375} onClick={() => {}}>
          <MapMarker
            icon={horseIcon}
            background="#fff"
            color="#147a2c"
            title="Stadnina koni"
          />
        </Marker>

        <Marker
          latitude={51.7845625}
          longitude={19.3130781}
          onClick={() => {}}
          anchor="bottom"
        >
          <StyledLogo />
        </Marker>
      </StyledMapbox>
    </StyledWrapper>
  );
};

export default Map;
