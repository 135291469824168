import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interface/ChildImageSharp";
import ScrollBottom from "@components/atoms/ScrollBottom/ScrollBottom";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1680px;
  width: 90%;
  height: 100%;
  position: relative;

  @media (max-width: 1024px) {
    width: fit-content;
    margin: auto;
    text-align: center;
  }
`;

const StyledHeadline = styled.h1`
  font-weight: 500;
  font-size: 7.6rem;
  color: ${({ theme }) => theme.background};
  margin: 0;

  @media (max-width: 720px) {
    font-size: 4.2rem;
  }
`;

const StyledParagraph = styled.p`
  font-size: 3.6rem;
  text-decoration: underline;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.primary};

  @media (max-width: 720px) {
    font-size: 2rem;
  }
`;

const StyledImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const StyledOverlay = styled.div`
  background: linear-gradient(
    92.48deg,
    #000000 2.27%,
    rgba(0, 0, 0, 0.6) 49.15%,
    rgba(0, 0, 0, 0) 98.68%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.75;
  z-index: 2;
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledScrollBottom = styled(ScrollBottom)`
  left: 0;
  bottom: 0;
  position: absolute;
  transform: translateY(20%);

  @media (max-width: 1024px) {
    left: 50%;
    transform: translateX(-50%) translateY(20%);
  }
`;

const Hero = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledHeadline>Pałacowa Park</StyledHeadline>
        <StyledParagraph>Zamieszkaj wyjątkowo</StyledParagraph>

        <StyledScrollBottom />
      </StyledInnerWrapper>

      <StyledImageWrapper>
        <StyledOverlay />
        <StyledImage
          image={getImage(file.childImageSharp.gatsbyImageData)}
          alt=""
        />
      </StyledImageWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
      }
    }
  }
`;

export default Hero;
