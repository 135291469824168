import React, { useState } from "react";
import styled from "styled-components";
import SectionSubHeadline from "@components/atoms/SectionSubHeadline/SectionSubHeadline";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { zoomInIcon } from "@assets/icons";
import { Icon } from "@iconify/react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { cssEaseOutExpo } from "@theme/easing";
import Button from "@components/atoms/Button/Button";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1700px;
  width: 90%;
  margin: 140px auto 0;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 720px) {
    margin: 80px auto 0;
  }
`;

const StyledHeadline = styled.h2`
  margin: 20px 0 80px;
  font-size: 4.8rem;
  font-weight: 500;

  @media (max-width: 720px) {
    font-size: 2.4rem;
    margin: 15px 0 40px;
  }
`;

const StyledSubHeadline = styled(SectionSubHeadline)`
  order: -1;
`;

const StyledImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 5%;

  @media (max-width: 1024px) {
    gap: 30px 4%;
  }

  @media (max-width: 720px) {
    gap: 30px 0;
  }
`;

const StyledImageWrapper = styled.div<{ $isHidden: boolean }>`
  width: 30%;
  height: 17.44vw;
  max-height: 335px;
  position: relative;
  cursor: pointer;

  ${({ $isHidden }) => $isHidden && "display: none;"}

  svg {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    font-size: 4rem;
    z-index: 22;
    color: ${({ theme }) => theme.primary};
    transition: transform 0.5s ${cssEaseOutExpo}, opacity 0.5s ${cssEaseOutExpo};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.5s ${cssEaseOutExpo};
  }

  :hover {
    svg {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    .gatsby-image-wrapper {
      filter: brightness(40%);
    }
  }

  @media (max-width: 1024px) {
    width: 46%;
    height: 28vw;
  }

  @media (max-width: 720px) {
    width: 100%;
    height: 60vw;
  }
`;

const StyledButton = styled(Button)`
  width: 200px;
  margin: 50px auto 0;
`;

const BuildGallery = () => {
  const [maxImages, setMaxImages] = useState<number>(9);
  const { directus } = useStaticQuery<Query>(query);

  const handleButtonClick = () => {
    setMaxImages((prevState) => prevState + 9);
  };

  console.log(directus);

  return (
    <StyledSection>
      <StyledHeadline>Zdjęcia z budowy</StyledHeadline>
      <StyledSubHeadline>Galeria zdjęć</StyledSubHeadline>

      <Gallery>
        <StyledImagesWrapper>
          {directus.Build_Gallery.images_list.map((item, index) => (
            <Item
              key={index}
              original={
                (
                  item.directus_files_id?.imageFile.childImageSharp.fixed
                    .images as any
                )?.fallback.src
              }
              width={
                item.directus_files_id?.imageFile.childImageSharp.fixed
                  .width as number
              }
              height={
                item.directus_files_id?.imageFile.childImageSharp.fixed
                  .height as number
              }
            >
              {({ ref, open }) => (
                <StyledImageWrapper
                  ref={ref as any}
                  onClick={open}
                  $isHidden={index >= maxImages}
                >
                  <GatsbyImage
                    image={getImage(
                      item.directus_files_id?.imageFile.childImageSharp
                        .gatsbyImageData
                    )}
                    alt=""
                  />
                  <Icon icon={zoomInIcon} />
                </StyledImageWrapper>
              )}
            </Item>
          ))}
        </StyledImagesWrapper>
      </Gallery>
      {maxImages < directus.Build_Gallery.images_list.length && (
        <StyledButton onClick={handleButtonClick}>Zobacz więcej</StyledButton>
      )}
    </StyledSection>
  );
};

interface Query {
  directus: {
    Build_Gallery: {
      images_list: {
        directus_files_id: {
          id: string;
          imageFile: {
            childImageSharp: {
              gatsbyImageData: ImageDataLike;
              fixed: ImageDataLike;
            };
          };
        };
      }[];
    };
  };
}

const query = graphql`
  {
    directus {
      Build_Gallery {
        images_list {
          directus_files_id {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
                fixed: gatsbyImageData(quality: 100, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`;

export default BuildGallery;
