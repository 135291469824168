import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interface/ChildImageSharp";
import Teaser from "@components/molecules/Teaser/Teaser";
import { ReactComponent as LogoGray } from "@assets/vectors/logo-gray.svg";

const StyledWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
`;

const StyledTeaserWrapper = styled.div`
  margin-bottom: 120px;
  position: relative;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }

  @media (max-width: 720px) {
    margin-bottom: 50px;
  }
`;

const StyledLogo = styled(LogoGray)`
  position: absolute;
  width: 320px;
  height: auto;

  ${StyledTeaserWrapper}:nth-of-type(1) & {
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateY(30%);
  }

  ${StyledTeaserWrapper}:nth-of-type(2) & {
    left: 0;
    top: 0;
    z-index: -1;
    transform: translateY(-30%);
  }

  ${StyledTeaserWrapper}:nth-of-type(3) & {
    right: 0;
    top: 0;
    z-index: -1;
    transform: translateY(-30%);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const TeasersSection = () => {
  const { first, second, third } = useStaticQuery<{
    first: ChildImageSharp;
    second: ChildImageSharp;
    third: ChildImageSharp;
  }>(query);

  return (
    <StyledWrapper>
      <StyledTeaserWrapper>
        <Teaser
          image={first}
          title="Centrum Twojego <span>Wszechświata</span>"
          description={[
            "Pałacowa Park to miejsce w którym będzie się toczyło Twoje życie zgodnie z tym, jak je sobie wymyślisz i zaplanujesz. Może być ciche i spokojne, miejsce odprężenia i zasłużonego relaksu lub miejsce pracy, rozrywki i aktywnej zabawy z dziećmi oraz przyjaciółmi.",
            "Zarówno Ty jak i każdy z Twojej rodziny odnajdzie pełen komfort wewnątrz domu oraz swobodę na podwórku. To miejsce dla Ciebie na stworzenie nowego stylu życia, blisko Łodzi, ale w cichej i zielonej okolicy.",
          ]}
        />
        <StyledLogo />
      </StyledTeaserWrapper>

      <StyledTeaserWrapper>
        <Teaser
          image={second}
          title="Dom, do którego <span>chce się wracać</span>"
          description={[
            "Zaprojektowaliśmy domy, w których każdy znajdzie miejsce dla siebie. Na dole jest przestronny salon z aneksem kuchennym, gdzie można spędzić czas wspólnie z bliskimi, obejrzeć film i ogrzać się przy kominku.",
            "Z myślą o komforcie Twojej rodziny, na górze zaplanowaliśmy 3 sypialnie. Dopełnieniem projektu domu są 2 łazienki, garderoba z pralnią oraz garaż.",
            "Dodatkowe miejsce parkingowe jest dostępne na podjeździe przed domem, a zaparkowanie kolejnego auta gości nie będzie żadnym problemem.",
          ]}
          reversed
        />

        <StyledLogo />
      </StyledTeaserWrapper>

      <StyledTeaserWrapper>
        <Teaser
          image={third}
          title="Wspólne chwile <span>na świeżym powietrzu</span>"
          description={[
            "W ciepłe dni możesz się cieszyć możliwościami które daje taras oraz własny ogródek. Poranna kawa na tarasie oraz kolacja na świeżym powietrzu przy zachodzie słońca ze znajomymi sprawią, że poczujesz się tu wyjątkowo. Ogródek z zieloną trawą jest świetnym miejscem do zabawy z dziećmi i z psem.",
            "Możesz na nim cieszyć się słońcem, pięknem kwiatów, uprawiać własne owoce lub zioła, albo zbudować plac zabaw z basenem – ogranicza Cię tylko Twoja wyobraźnia.",
          ]}
        />

        <StyledLogo />
      </StyledTeaserWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    first: file(name: { eq: "vis1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
      }
    }

    second: file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
      }
    }

    third: file(name: { eq: "vis2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
      }
    }
  }
`;

export default TeasersSection;
