import React from "react";
import BasicTemplate from "@templates/BasicTemplate";
import Hero from "@components/molecules/Hero/Hero";
import IconsSection from "@components/molecules/IconsSection/IconsSection";
import TeasersSection from "@components/organisms/TeasersSection/TeasersSection";
import AboutEstate from "@components/molecules/AboutEstate/AboutEstate";
import AboutHouses from "@components/molecules/AboutHouses/AboutHouses";
import Location from "@components/molecules/Location/Location";
import InteractiveVisualization from "@components/molecules/InteractiveVisualization/InteractiveVisualization";
import Gallery from "@components/molecules/Gallery/Gallery";
import BuildGallery from "@components/molecules/BuildGallery/BuildGallery";
import Contact from "@components/molecules/Contact/Contact";

const Index = () => (
  <BasicTemplate title="Pałacowa Park" renderHero={Hero} isSubPage={false}>
    <IconsSection />
    <TeasersSection />
    <AboutEstate />
    <AboutHouses />
    <Location />
    <InteractiveVisualization />
    <Gallery />
    <BuildGallery />
    <Contact />
  </BasicTemplate>
);

export default Index;
