import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SectionSubHeadline from "@components/atoms/SectionSubHeadline/SectionSubHeadline";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interface/ChildImageSharp";
import { ReactComponent as Background } from "@assets/vectors/about_estate_background.svg";

const StyledWrapper = styled.section`
  margin: 120px 0;
  padding: 100px 0;
  position: relative;

  @media (min-width: 1530px) {
    padding: 100px 0 150px;
  }

  @media (min-width: 1630px) {
    padding: 100px 0 200px;
  }

  @media (min-width: 1730px) {
    padding: 100px 0 250px;
  }

  @media (max-width: 1024px) {
    background: ${({ theme }) => theme.foreground};
    padding: 80px 0;
    margin: 120px 0 0;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1700px;
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 720px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 58.33vw;
  max-width: 950px;
  height: 40.41vw;
  max-height: 600px;
  margin-right: 90px;

  @media (max-width: 1520px) {
    width: 48vw;
    height: 32vw;
  }

  @media (max-width: 1340px) {
    width: 42vw;
    height: 28vw;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    width: 100%;
    height: 63vw;
    max-width: 720px;
    max-height: 454px;
    margin-bottom: 60px;
  }
`;

const StyledRightWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledHeadline = styled.h2`
  margin: 25px 0;
  font-size: 3.2rem;
  line-height: 1.3;
  font-weight: 500;
  color: ${({ theme }) => theme.background};

  @media (max-width: 720px) {
    font-size: 2.4rem;
    margin: 15px 0;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 0 15px;
  color: ${({ theme }) => theme.washRegular};
  line-height: 1.6;

  :last-of-type {
    margin: 0;
  }
`;

const StyledSubHeadline = styled(SectionSubHeadline)`
  order: -1;
`;

const StyledBackground = styled(Background)`
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const AboutEstate = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  return (
    <StyledWrapper id="about_estate">
      <StyledInnerWrapper>
        <StyledImage
          image={getImage(file.childImageSharp.gatsbyImageData)}
          alt=""
        />

        <StyledRightWrapper>
          <StyledSubHeadline>Opis osiedla</StyledSubHeadline>

          <StyledHeadline>
            Osiedle Pałacowa Park to takie miejsce, w którym natura, cisza i
            przestrzeń tworzą niepowtarzalną całość
          </StyledHeadline>

          <StyledParagraph>
            Domy zlokalizowane są w Rąbieniu, przy ulicy Pałacowej. Rąbień jest
            prężnie rozwijającą się miejscowością, z bardzo dobrą infrastrukturą
            zaspokajającą większość potrzeb życiowych nawet dla osób
            posiadających wyższe wymagania.
          </StyledParagraph>

          <StyledParagraph>
            Inwestycja znajduję się w cichej i zielonej okolicy, w pobliżu
            rzeczki Lubczyna. Przepływający w granicy działki strumyk tworzy
            wyjątkową atmosferę. Okolica jest pełna ptaków oraz dzikich
            zwierząt, co sprawia poczucie że natura jest na wyciągnięcie ręki.
          </StyledParagraph>

          <StyledParagraph>
            Osiedle projektowane jest z myślą o rodzinach szukających domu w
            rozsądnej cenie, które pragną mieć stały kontakt z naturą, możliwość
            wypoczynku w swoim ogrodzie po codziennej pracy a jednocześnie
            zależy im, by mieszkać w niedalekiej odległości od pełnej
            infrastruktury miejskiej.
          </StyledParagraph>
        </StyledRightWrapper>
      </StyledInnerWrapper>

      <StyledBackground />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "interactive_vis" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
      }
    }
  }
`;

export default AboutEstate;
