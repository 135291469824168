import React from "react";
import styled from "styled-components";
import SectionSubHeadline from "@components/atoms/SectionSubHeadline/SectionSubHeadline";
import Slider from "@components/molecules/Gallery/Slider/Slider";
import { graphql, useStaticQuery } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";

const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1700px;
  width: 90%;
  margin: 140px auto 0;
  position: relative;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 720px) {
    margin: 80px auto 0;
  }
`;

const StyledHeadline = styled.h2`
  margin: 20px 0 50px;
  font-size: 4.8rem;
  font-weight: 500;
  max-width: 760px;

  @media (max-width: 720px) {
    margin: 15px 0;
    font-size: 2.4rem;
  }
`;

const StyledSubHeadline = styled(SectionSubHeadline)`
  order: -1;
`;

const Gallery = () => {
  const { directus } = useStaticQuery<Query>(query);

  return (
    <StyledWrapper id="gallery">
      <StyledHeadline>
        Sprawdź jak możesz mieszkać na osiedlu Pałacowa Park.
      </StyledHeadline>
      <StyledSubHeadline>Galeria wizualizacji</StyledSubHeadline>

      <Slider
        images={directus.Gallery.images_list.map(
          (item) =>
            item.directus_files_id.imageFile.childImageSharp.gatsbyImageData
        )}
        fixedImages={directus.Gallery.images_list.map((item) => {
          const { width, height, images } =
            item.directus_files_id.imageFile.childImageSharp.fixed;
          return {
            src: (images as any).fallback.src as string,
            width: width as number,
            height: height as number,
          };
        })}
      />
    </StyledWrapper>
  );
};

interface Query {
  directus: {
    Gallery: {
      images_list: {
        directus_files_id: {
          id: string;
          imageFile: {
            childImageSharp: {
              gatsbyImageData: ImageDataLike;
              fixed: ImageDataLike;
            };
          };
        };
      }[];
    };
  };
}

const query = graphql`
  {
    directus {
      Gallery {
        images_list {
          directus_files_id {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 80)
                fixed: gatsbyImageData(
                  quality: 100
                  layout: FIXED
                  formats: JPG
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default Gallery;
