import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { cssEaseInOutCirc, cssEaseOutExpo } from "@theme/easing";
import { Gallery as PhotoSwipeGallery, Item } from "react-photoswipe-gallery";
import PhotoSwipe from "photoswipe";
import { Icon } from "@iconify/react";
import { arrowLeftBoldIcon, arrowRightBoldIcon } from "@assets/icons";
import { useLocation } from "react-use";

SwiperCore.use([Pagination, Navigation]);

const StyledWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  position: relative;

  .swiper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }

  .swiper-wrapper {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
  }

  .swiper-slide {
    transform: scale(0.9);
    opacity: 0.4;
    transition: transform 1s ${cssEaseOutExpo}, opacity 0.7s ${cssEaseInOutCirc};

    &-active {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 40px;
  }

  @media (max-width: 720px) {
    margin-top: 20px;
  }
`;

const StyledImageWrapper = styled.div``;

const StyledImage = styled(GatsbyImage)`
  width: 63.54vw;
  max-width: 1220px;
  height: 35.41vw;
  max-height: 680px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 55vw;
  }
`;

const StyledPagination = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
    margin-right: 30px;
    position: relative;
    display: block;

    ::before {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.primary};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      pointer-events: none;
      transition: transform 1s ${cssEaseOutExpo};
    }

    &-active::before {
      transform: translate(-50%, -50%) scale(1);
    }

    :last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledNavigationWrapper = styled.div`
  display: flex;

  button {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    transition: color 0.5s ${cssEaseOutExpo}, border 0.5s ${cssEaseOutExpo},
      background 0.5s ${cssEaseOutExpo};

    :first-of-type {
      margin-right: 30px;
    }

    :hover {
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
    }

    :active {
      transform: scale(0.9);
    }

    &.is-disable {
      color: ${({ theme }) => theme.foreground};
      border: 1px solid ${({ theme }) => theme.foreground};
      cursor: not-allowed;

      :hover {
        background: transparent;
        color: ${({ theme }) => theme.foreground};
      }
    }
  }

  @media (min-width: 1025px) {
    button {
      position: absolute;
      top: 37%;
    }

    button:first-of-type {
      left: 48px;
    }

    button:last-of-type {
      right: 48px;
    }
  }

  @media (min-width: 1080px) {
    button {
      position: absolute;
      top: 37%;
    }

    button:first-of-type {
      left: 60px;
    }

    button:last-of-type {
      right: 60px;
    }
  }

  @media (min-width: 1160px) {
    button {
      position: absolute;
      top: 37%;
    }

    button:first-of-type {
      left: 70px;
    }

    button:last-of-type {
      right: 70px;
    }
  }

  @media (min-width: 1230px) {
    button {
      position: absolute;
      top: 37%;
    }

    button:first-of-type {
      left: 80px;
    }

    button:last-of-type {
      right: 80px;
    }
  }

  @media (min-width: 1330px) {
    button {
      position: absolute;
      top: 37%;
    }

    button:first-of-type {
      left: 90px;
    }

    button:last-of-type {
      right: 90px;
    }
  }

  @media (min-width: 1430px) {
    button {
      position: absolute;
      top: 37%;
    }

    button:first-of-type {
      left: 98px;
    }

    button:last-of-type {
      right: 98px;
    }
  }

  @media (min-width: 1500px) {
    button:first-of-type {
      left: 110px;
    }

    button:last-of-type {
      right: 110px;
    }
  }

  @media (min-width: 1600px) {
    button:first-of-type {
      left: 120px;
    }

    button:last-of-type {
      right: 120px;
    }
  }

  @media (min-width: 1700px) {
    button:first-of-type {
      left: 130px;
    }

    button:last-of-type {
      right: 130px;
    }
  }

  @media (min-width: 1700px) {
    button:first-of-type {
      left: 140px;
    }

    button:last-of-type {
      right: 140px;
    }
  }

  @media (min-width: 1800px) {
    button:first-of-type {
      left: 150px;
    }

    button:last-of-type {
      right: 150px;
    }
  }

  @media (min-width: 1900px) {
    button:first-of-type {
      left: 140px;
    }

    button:last-of-type {
      right: 140px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    margin-top: 60px;
  }

  @media (max-width: 720px) {
    margin-top: 40px;

    button {
      width: 60px;
      height: 60px;
      font-size: 1.8rem;
    }
  }
`;

const Slider: FC<Props> = ({ images, fixedImages }) => {
  const [photoswipe, setPhotoswipe] =
    useState<PhotoSwipe<PhotoSwipe.Options>>();
  const [swiper, setSwiper] = useState<SwiperCore>();
  const { pathname } = useLocation();

  useEffect(() => {
    photoswipe?.listen("close", () => {
      swiper.slideTo(photoswipe.getCurrentIndex(), 0);
    });
  }, [photoswipe]);

  useEffect(() => {
    photoswipe?.init();
  }, [pathname]);

  return (
    <StyledWrapper>
      <PhotoSwipeGallery onOpen={(photoswipe) => setPhotoswipe(photoswipe)}>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            1025: {
              slidesPerView: "auto",
              centeredSlides: true,
              pagination: {
                el: ".swiper-pagination",
              },
            },
          }}
          spaceBetween={60}
          grabCursor
          onInit={(swiper) => setSwiper(swiper)}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
            disabledClass: "is-disable",
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <Item
                original={fixedImages[index].src}
                width={fixedImages[index].width}
                height={fixedImages[index].height}
              >
                {({ ref, open }) => (
                  <StyledImageWrapper ref={ref as any} onClick={open}>
                    <StyledImage image={getImage(image)} alt="" />
                  </StyledImageWrapper>
                )}
              </Item>
            </SwiperSlide>
          ))}
        </Swiper>
      </PhotoSwipeGallery>

      <StyledNavigationWrapper>
        <button aria-label="Poprzednie zdjęcie" className="swiper-button-prev">
          <Icon icon={arrowLeftBoldIcon} />
        </button>
        <button aria-label="Następne zdjęcie" className="swiper-button-next">
          <Icon icon={arrowRightBoldIcon} />
        </button>
      </StyledNavigationWrapper>

      <StyledPagination className="swiper-pagination" />
    </StyledWrapper>
  );
};

interface Props {
  images: ImageDataLike[];
  fixedImages: { src: string; width: number; height: number }[];
}

export default Slider;
