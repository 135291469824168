import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easing";

const StyledWrapper = styled.div<{ $color: string; $background: string }>`
  background: ${({ $background }) => $background};
  width: 38px;
  height: 38px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  position: relative;

  svg {
    font-size: 2rem;
    color: ${({ $color }) => $color};
  }
`;

const StyledTitle = styled.span<{ $color: string; $background: string }>`
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, calc(15% + 10px));
  width: fit-content;
  color: ${({ $color }) => $color};
  background: ${({ $background }) => $background};
  white-space: nowrap;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.2rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.5s ${cssEaseOutExpo};

  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translate(-50%, 10px);
  }
`;

const MapMarker: FC<Props> = ({ icon, color, background, title }) => (
  <StyledWrapper $color={color} $background={background}>
    <Icon icon={icon} />
    <StyledTitle $color={color} $background={background}>
      {title}
    </StyledTitle>
  </StyledWrapper>
);

interface Props {
  icon: any;
  background: string;
  color: string;
  title: string;
}

export default MapMarker;
