import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import LocationBlock from "@components/atoms/LocationBlock/LocationBlock";

const StyledWrapper = styled.div`
  max-width: 790px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeadline = styled.h3`
  font-size: 2.4rem;
  text-align: center;
  margin: 0 0 20px;
`;

const StyledParagraph = styled.p`
  text-align: center;
  margin: 0 0 30px;
  line-height: 2;

  @media (max-width: 1390px) {
    max-width: 520px;

    ${StyledWrapper}:first-of-type & {
      max-width: 480px;
    }
  }

  @media (max-width: 960px) {
    max-width: 380px;

    ${StyledWrapper}:first-of-type & {
      max-width: 340px;
    }
  }

  @media (max-width: 780px) {
    max-width: 280px;

    ${StyledWrapper}:first-of-type & {
      max-width: 269px;
    }
  }

  @media (max-width: 720px) {
    max-width: unset !important;
  }
`;

const StyledBlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const StyledBlock = styled(LocationBlock)``;

const Column: FC<Props> = ({ className, title, description, items }) => (
  <StyledWrapper className={className}>
    <StyledHeadline>{title}</StyledHeadline>
    <StyledParagraph>{description}</StyledParagraph>
    <StyledBlocksWrapper>
      {items.map(({ icon, description }, index) => (
        <StyledBlock icon={icon} description={description} key={index} />
      ))}
    </StyledBlocksWrapper>
  </StyledWrapper>
);

interface Item {
  icon: any;
  description: ReactNode;
}

interface Props {
  title: string;
  description: string;
  items: [Item, Item, Item, Item, Item, Item];
  className?: string;
}

export default Column;
