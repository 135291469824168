import React, { FC } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChildImageSharp } from "@interface/ChildImageSharp";

const StyledWrapper = styled.section<{ $isReversed: boolean }>`
  width: 100%;
  display: flex;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  ${({ $isReversed }) =>
    $isReversed &&
    css`
      flex-direction: row-reverse;
    `};

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 90%;
    max-width: 720px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 70%;
  max-width: 1300px;
  min-height: 440px;

  @media (max-width: 1820px) {
    width: 65%;
  }

  @media (max-width: 1540px) {
    width: 60%;
  }

  @media (max-width: 1360px) {
    width: 50%;
  }

  @media (max-width: 1140px) {
    width: 45%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 63vw;
    max-width: 720px;
    max-height: 454px;
    min-height: unset;
  }
`;

const StyledRightWrapper = styled.div<{ $isReversed: boolean }>`
  flex: 1;
  margin: 0 0 0 70px;
  padding: 0 70px 0 0;

  ${({ $isReversed }) =>
    $isReversed &&
    css`
      margin: 0 70px 0 0;
      padding: 0 0 0 70px;
    `};

  @media (max-width: 1024px) {
    margin: 50px 0 0;
    padding: 0;
  }

  @media (max-width: 720px) {
    margin: 40px 0 0;
  }
`;

const StyledHeadline = styled.h2`
  font-size: 4.2rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 30px;
  max-width: 450px;

  span {
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    text-align: center;
    max-width: unset;
  }

  @media (max-width: 720px) {
    font-size: 2.4rem;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 0 15px;
  line-height: 1.6;
  max-width: 450px;
  color: ${({ theme }) => theme.washRegular};

  :last-of-type {
    margin: 0;
  }

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const Teaser: FC<Props> = ({
  className,
  image,
  title,
  description,
  reversed,
}) => (
  <StyledWrapper className={className} $isReversed={reversed}>
    <StyledImage
      image={getImage(image.childImageSharp.gatsbyImageData)}
      alt={title}
    />
    <StyledRightWrapper $isReversed={reversed}>
      <StyledHeadline dangerouslySetInnerHTML={{ __html: title }} />
      {description.map((desc, index) => (
        <StyledParagraph key={index}>{desc}</StyledParagraph>
      ))}
    </StyledRightWrapper>
  </StyledWrapper>
);

interface Props {
  reversed?: boolean;
  className?: string;
  image: ChildImageSharp;
  title: string;
  description: string[];
}

export default Teaser;
