import React, { FC } from "react";
import styled from "styled-components";
import { arrowDownIcon } from "@assets/icons";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easing";
import { useMainContext } from "@context";

const StyledWrapper = styled.button`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: 0;
  border: 2px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  font-size: 3rem;
  cursor: pointer;
  transition: 0.5s background ${cssEaseOutExpo}, 0.5s color ${cssEaseOutExpo};

  :hover {
    color: #fff;
    background: ${({ theme }) => theme.primary};
  }

  @media (max-width: 720px) {
    width: 128px;
    height: 128px;
  }
`;

const ScrollBottom: FC<Props> = ({ className }) => {
  const { scroller } = useMainContext();

  return (
    <StyledWrapper
      className={className}
      onClick={() => scroller?.to(window.innerHeight)}
    >
      <Icon icon={arrowDownIcon} />
    </StyledWrapper>
  );
};

interface Props {
  className?: string;
}

export default ScrollBottom;
